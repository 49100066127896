@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Nunito:wght@200;300;400;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500;600;700;800;900&family=Signika:wght@300;400;500;600;700&display=swap);
#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12vh;
  border-bottom: .5px solid #ebebeb;
  padding: 10px 40px 10px 30px;
  z-index: 105;
}
/* Left Section */
#navbar .left-section-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

#navbar .left-section-navbar .logo{
  width: 25px;
}

#navbar .left-section-navbar h2{
  font-family: 'Signika', sans-serif;
  color: #03015D;
  font-size:20px;
  font-weight: 700;
  margin-left:12px ;
}

/* Right Section */
#navbar .right-section-navbar .user {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -40px;
}
#navbar .right-section-navbar .username {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#navbar .right-section-navbar .user h3 {
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  font-size:20px;
  position:relative;
  left:-15px;
  font-weight: 400;
  color: #C4C4C4;
}
.right-section-navbar .user svg {
  font-weight: lighter;
  position: relative;
  top:1px;
  color:#C4C4C4;
}
#navbar .right-section-navbar img {
  width:40px;
  height: 40px;
}
#navbar .right-section-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navbar .right-section-navbar .down{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ececec;
  position:absolute;;
  left:-20px;
  top:40px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  z-index: 14;
}

#navbar .right-section-navbar .down h4{
  background-color: white;
  width: 100%;
  font-size:13px;
  padding:15px 35px;
  font-family: 'Noto Sans', sans-serif;
  color: #9A9A9A;
  cursor: pointer;
  text-decoration: none;
}
#navbar .right-section-navbar .down h4 a{
  color: #9A9A9A;
  text-decoration: none;
}
#navbar .right-section-navbar .down h4:hover{
  background-color: rgb(226, 224, 224);
}
.up_icon {
  transform: rotate(-180deg);
  transition:.2s ease;
}
.down_icon {
  transform: rotate(0deg);
  transition:.2s ease;
}


@media(max-width: 500px) {
  #navbar .left-section-navbar {
    margin-left: 0px !important;
  }
  #navbar .left-section-navbar .logo{
    width: 20px !important;
  }
  #navbar .left-section-navbar h2{
    font-size:17px !important;
    margin-left:7px !important;
  }
  .bg {
    width:30px !important;
    height: 30px !important;
  }
  #navbar .right-section-navbar .user {
    left: -20px !important;
  }
  #navbar .right-section-navbar .user h3 {
    font-size:16px !important;
    left:-10px !important;
  }
}
@media(max-height: 500px) {
  .bg {
    width:25px !important;
    height: 25px !important;
  }
}
@media(min-width:1600px) {
  #navbar .left-section-navbar .logo{
    width: 40px !important;
  }
  #navbar .left-section-navbar h2{
    font-size:25px !important;
    margin-left:15px !important;
  }
  .bg {
    width:60px !important;
    height: 60px !important;
  }
  #navbar .right-section-navbar .user {
    left: -30px !important;
  }
  #navbar .right-section-navbar .user h3 {
    font-size:22px !important;
    left:-10px !important;
  }
}

#main {
  display:flex;
  z-index: 15;
}

/* Menu */
#main .menu {
  height:88vh;
  width: 20%;
  border-right: .5px solid #D9D9D9;
}
.menu .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 20px;
}
.menu .container .managment {
  font-family: "Noto Sans", sans-serif;
  color: #C4C4C4;
  font-size:12px;
  padding: 10px 15px;
  margin-top: 15px;
}
.menu .container .content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 15px;
  padding-right:35px;
  border-radius: 10px;
}
.menu .container .content h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 400;
  font-size:17px;
  margin-left:15px;
  color: #C4C4C4;
}
.menu .container .content svg {
  color: #C4C4C4;
  margin-left:15px;
  width: 28px;
  height:28px;
}
.menu .container .content:hover {
  color: #332A7C !important;
  border-color: #332A7C !important;
  background: #EDEDF4 !important;
}
.active_color {
  color: #332A7C !important;
  border-color: #332A7C !important;
  background: #EDEDF4 !important;
}
.message_any_task  {
  position:absolute;
  top: 30%;
  left:50%;
  transform:translate(-50%, -50%);
}
.message_any_task p {
  color: #999999;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-weight: 400;
}

.completed-icon {
  width: 20px;
  height:20px;
  border-radius: 0 0 50px 50px;
  border:2px solid #C4C4C4;
}
.date-icon {
  width: 20px;
  height:20px;
  border:2px solid #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
  color:  #C4C4C4;
  font-family: 'Signika', sans-serif;
  font-size:10px;
}
.task-icon {
  position: relative;
  width: 20px;
  height:20px;
  border:2px solid #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Signika', sans-serif;
}
.task-icon span{
  position: absolute;
  width: 70%;
  height:2px;
  border:1px solid #C4C4C4;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  font-family: 'Signika', sans-serif;
}

.task-icon span:nth-child(1) {
  top: 20%;
}
.task-icon span:nth-child(3) {
  top: 80%;
}

.progress-icon {
  position: relative;
  width: 20px;
  height:15px;
  top:4px;
  border:2px solid #C4C4C4;
}
.progress-icon span:nth-child(1) {
  position: absolute;
  width: 70%;
  height:8px;
  border:2px solid #C4C4C4;
  top: -35%;
  left:50%;
  transform: translate(-50%, -50%);
}


/* Tasks */
#main .tasks {
  width: 80%;
  height: 88vh;
  overflow-y: auto;
  padding-bottom: 40px;
}
.tasks::-webkit-scrollbar {
  width: 10px;
}
.tasks::-webkit-scrollbar-track {
  background: #F4F3F3; 
  border-radius:15px;
}
.tasks::-webkit-scrollbar-thumb {
  background: #C4C4C4; 
  border-radius:15px;
}


.tasks .container{
  padding-bottom:10px;
}
.tasks .container .content {
  display: flex;
  justify-content: space-between;
  padding:40px 45px;
}
.tasks .container .content h2 {
  font-family: 'Rubik', sans-serif;
  font-size:47px;
  color: #03015D;
}
.tasks .container .content button {
  font-family: 'Poppins', sans-serif;
  font-size:15px;
  font-weight: 600;
  color: #ffffff;
  background: #03015D;
  border:none;
  outline: none;
  padding:0px 35px;
  border-radius:8px;
  cursor: pointer;
  z-index: 10;
  height:50px;
}
.count-todos {
  font-family: "Poppins" ,sans-serif;
  color: #C4C4C4;
  font-weight: 300;
  font-size: 20px;
} 
.tasky {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tasky .tasky-content {
  display:grid;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 25px;
  row-gap: 25px;
  padding:10px 0px;
}

.tasky .tasky-content {
  grid-template-columns: repeat(3, 23.5vw);
}

@media(max-width: 1100px) {
  .tasky .tasky-content {
    grid-column-gap: 20px !important;
    -webkit-column-gap: 20px !important;
            column-gap: 20px !important;
  }
  .tasky .tasky-content {
    grid-template-columns: repeat(2, 34vw);
  }
}
@media(max-width: 780px) {
  #main .menu {
    width: 25%;
  }
  #main .tasks {
    width: 75%;
  }
  .tasks .container .content {
    padding:40px 45px;
  }
  .tasks .container .content h2 {
    font-size:37px;
  }
  .count-todos {
  font-size: 17px !important;
  } 
  .tasks .container .content button {
    font-size:13px !important;
    padding:0px 25px;
    height:40px;
  }
  .tasky .tasky-content {
    grid-column-gap: 20px !important;
    -webkit-column-gap: 20px !important;
            column-gap: 20px !important;
  }
  .tasky .tasky-content {
    grid-template-columns: repeat(2, 30vw);
  }
}
@media(max-width: 660px) {
  .tasks .container .content {
    padding:30px 20px;
  }
  .tasks .container .content h2 {
    font-size:37px;
  }
  .count-todos {
  font-size: 17px !important;
  } 
  .menu .container .managment {
    font-size:10px !important;
    padding: 10px 5px !important;
  }
  .menu .content {
    padding: 10px 0px;
    padding-right: 15px !important;
  }
  .menu .container {
    padding: 10px 5px;
  }
  .menu .container .content h3 {
    font-size:13px;
  }
  .tasks .container .content button {
    font-size:13px !important;
    padding:0px 25px;
    height:40px;
  }
  .tasky .tasky-content {
    grid-column-gap: 20px !important;
    -webkit-column-gap: 20px !important;
            column-gap: 20px !important;
  }
  .tasky .tasky-content {
    grid-template-columns: repeat(1, 50vw);
  }
}
@media(max-width: 580px) {
  #main .menu {
    width: 35%;
  }
  #main .tasks {
    width: 65%;
  }
  .tasks .container .content h2 {
    font-size:20px;
  }
  .tasks::-webkit-scrollbar {
    width: 5px;
  }
  .count-todos {
  font-size: 11px !important;
  } 
  .tasks .container .content button {
    font-size:7px !important;
    padding:0px 10px;
    height:30px;
  }
}
@media(max-width: 500px) {
  .tasks .container .content h2 {
    font-size:18px;
  }
  .count-todos {
  font-size: 11px !important;
  } 
  .tasks .container .content button {
    font-size:7px !important;
    padding:0px 10px;
    height:30px;
  }
}

@media(max-width: 420px) {
  #main .menu {
    width: 40%;
  }
  #main .tasks {
    width: 60%;
  }
}

@media(min-width: 1600px) {
  .tasks .container .content {
    padding:50px 50px;
  }
  .tasks .container .content h2 {
    font-size:50px;
  }
  .tasky .tasky-content {
    grid-template-columns: repeat(4, 18vw);
  }
  .menu .container .managment {
    font-size:15px;
    padding: 10px 15px;
    margin-top: 25px;
  }
  .menu .container .content {
    padding: 15px 20px;
    padding-right:40px;
  }
  .menu .container .content h3 {
    font-size:25px;
  }

}
#new-task {
  width:100%;
  height: 100vh;
  background-color: rgba(0,0,0,.1);
  position: absolute;
  top:0;
  left:0;
  z-index: 20;
}
.newTask {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  display:flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: white;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  padding:3vh 5vh 5vh 5vh;
  border-radius: 15px;
  z-index: 300;
}
.newTask .input-content {
  margin-top: 15px;
}
#new-task h3 {
  color: #4a4a4a;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size:20px;
  padding-bottom: 5px;
}   
#new-task input, #new-task textarea {
  width: 50vh;
  padding: 10px 12px;
  outline: none;
}  
#new-task textarea {
  font-family: "Poppins", sans-serif;
  font-size:12px;
  font-weight: 400;
  height: 20vh;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}
.date-picker {
  width:40vh;
  margin-top:5px;
  font-family: "Poppins", sans-serif;
  font-size:12px;
  font-weight: 400;
}
.title-input {
  font-family: "Poppins", sans-serif;
  font-size:12px;
  font-weight: 400;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}

.btnNew {
  margin-top: 30px;
  width:100%;
  padding: 12px;
  background: #1E1D58;
  border: none;
  outline: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size:13px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
}
.close {
  position:absolute;
  top:15px;
  right: 20px;
  font-family: "Poppins", sans-serif;
  font-size:20px;
  font-weight: 500;
  color: #C4C4C4;
  cursor: pointer;
}

@media(max-width: 500px) {
  .newTask {
    padding:15px 20px 20px 20px;
  }
  .newTask .input-content {
    margin-top: 5px;
  }
  #new-task h3 {
    font-size:17px;
  }   
  #new-task input, #new-task textarea {
    width: 40vh;
    padding: 7px 12px;
  }  
  #new-task textarea {
    font-size:11px;
    height: 15vh;
  }
  .date-picker {
    width:40vh;
    margin-top:5px;
    font-size:11px;
  }
  .title-input {
    font-size:11px;
  }
  .btnNew {
    margin-top: 25px;
    padding: 11px;
    font-size:12px;
  }
  .close {
    top:10px;
    right: 15px;
    font-size:15px;
  }
}
.card {
  position: relative;
  background:white;
  box-shadow: 0px 2px 4px 1px #dadadac7;
  border-radius: 15px;
  transition: .5s ease-in;
}
.card__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:20px 20px; 
  height:100%;
}
.card .title {
  width: 80%;
  overflow: hidden;
}
.card .into:nth-child(1) div:nth-child(1) {
  display:flex;
  justify-content: space-between;
}
.time {
  display: flex;
}
.card .delete {
  cursor: pointer;
}
.card svg {
  color: #535353;
}
.into__down {
  display: flex;
  margin-top: 25px;
}
.into__down h4 {
  color:#4a4a4a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size:13px;
  letter-spacing: -1px;
}
.into__down .flag {
  position: absolute;
  left:45%;
}
.into__down .comp svg{
  color: rgb(131, 7, 7);
  cursor: pointer;
}
.orange {
  color:#f88808 !important;
}
.green {
  color: #37782C !important;
}
.red {
  color:#C91010 !important;
}
.text {
  width: 95%;
  height:85px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 15px;
}
.text::-webkit-scrollbar {
  width: 7px;
}
.text::-webkit-scrollbar-track {
  background: #F4F3F3; 
  border-radius:15px;
}
.text::-webkit-scrollbar-thumb {
  background: #C4C4C4; 
  border-radius:15px;
}

.scroll {
  overflow-y: scroll !important;
}
.noscroll {
  overflow-y: hidden !important;
}

.card h2{
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  color: #535353;
  font-weight: 800;
}
.card h4{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  position: relative;
  left: 5px;
  top:-3px;
  color: #4A4A4A;
}
.card p{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #4E4D4D;
}
.completed {
  width:15px; 
  height: 18px;
  border: 2px solid #C4C4C4;
  background-color: white !important;
  position: absolute;
  left:55%;
  cursor: pointer;
  border-radius: 0px 0px 50px 50px;
}
.gray {
  background-color:  #C4C4C4 !important;
}

@media(max-width: 780px) {
  .into__down {
    margin-top: 5px;
  }
  .into__down h4 {
    font-size:12px;
    position: relative;
    top:-1px;
  }
  .into__down .flag {
    left:55%;
  }
  .completed {
    left:70%;
  }
  .text {
    width: 95%;
    height:85px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 15px;
  }
}
#sign-up {
  width:100%;
  height: 100vh;
  background-color: #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sign-up .container {
  width: 55vw;
  height: 70vh;
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
#sign-up .container .content {
   width: 50%;
   height:100%;
}
#sign-up .container .content:nth-child(2) .into{
  padding:10vh 3.5vw;
}
#sign-up .container .content:nth-child(2) h2 {
  font-family: 'Rubik', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 20px;
}
#sign-up .container .content:nth-child(2) p {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 12px;
  margin-top:10px;
}
#sign-up .container .content .already {
  color: rgb(139, 139, 139);
  font-family: "Poppins", sans-serif;
  font-weight: 300; 
  font-size:13px;
  margin-top: 50px;
}
#sign-up .container .content .already a{
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  font-size:13px;
  margin-top: 2vh;
  text-decoration: none;
}
#sign-up .container .content:nth-child(1) {
  border-radius: 15px;
  background-color: #ffffff;
}
#sign-up .container .content:nth-child(2) {
  border-radius: 15px;
  background-color: #03015D;
}
#sign-up .container .content:nth-child(1) .into  {
  position: relative;
  height:100%;
}
#sign-up .container .content:nth-child(1) .into form {
  padding: 4vh 1.8vw;
}
#sign-up .container .content:nth-child(1) .into .sign-up_section{
  position: absolute;
  bottom: 5vh;
}

#sign-up .container .content:nth-child(1) .into form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
  width: 22vw;
   padding: 1.8vh 15px;
   border: 1px solid #C4C4C4;
   border-radius: 7px;
   margin-top: 15px;
   outline: none;
   font-size: 15px;
}
#sign-up .container .content:nth-child(1) .into form button {
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  width: 22vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 15px;
  outline: none;
  font-size: 15px;
  background:  #03015D;
  color:white;
  cursor: pointer;
}
#sign-up .container .content .error {
  background: #F29494;
  padding: 1.4vh 2vw;
  border-radius: 7px;
  width:22vw;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
}


@media(max-width: 600px) {
  #sign-up .container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0;
  }
  #sign-up .container .content {
    width: 100%;
    height:100%;
 }
 #sign-up .container .content:nth-child(1) {
  border-radius: 0;
 }
 #sign-up .container .content:nth-child(2) .into {
  padding: 80px 40px;
}
 #sign-up .container .content:nth-child(2) {
  border-radius: 0;
 }
 #sign-up .container .content:nth-child(1) .into form div:nth-child(2){
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   width: 90%;
 }
 #sign-up .container .content:nth-child(1) .into form {
    padding:10px 30px;
  }
 #sign-up .container .content:nth-child(1) .into form input {
   width: 90%;
   padding:7px 15px;
 }
 #sign-up .container .content:nth-child(1) .into form .sign-up_section {
  width: 90%;
  bottom: 15px;
}
 #sign-up .container .content:nth-child(1) .into form button {
  width: 84%;
  padding:8px 15px;
}
#sign-up .container .content .error {
  padding: 5px 2vw;
  width:90%;
}
}
#login {
  width:100%;
  height: 100vh;
  background-color: #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login .container {
  width: 52vw;
  height: 55vh;
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
#login .container .content {
   width: 50%;
   height:100%;
}
#login .container .content:nth-child(1) .into{
  padding:10vh 3.5vw;
}
#login .container .content:nth-child(1) h2 {
  font-family: 'Rubik', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 20px;
}
#login .container .content:nth-child(1) p {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 12px;
  margin-top:2.5px;
}
#login .container .content:nth-child(1) a {
  font-family: 'Poppins', sans-serif;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
}
#login .container .content:nth-child(2) {
  border-radius: 15px;
  background-color: #ffffff;
}
#login .container .content:nth-child(1) {
  border-radius: 15px;
  background-color: #03015D;
}
#login .container .content:nth-child(2) .into  {
  position: relative;
  height:100%;
}
#login .container .content:nth-child(2) .into form {
  padding: 4vh 1.8vw;
}
#login .container .content:nth-child(2) .into .login_section{
  position: absolute;
  bottom: 4vh;
}

#login .container .content:nth-child(2) .into form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
  width: 22vw;
   padding: 1.8vh 15px;
   border: 1px solid #C4C4C4;
   border-radius: 7px;
   margin-top: 15px;
   outline: none;
   font-size: 15px;
}
#login .container .content:nth-child(2) .into form button {
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  width: 22vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 15px;
  outline: none;
  font-size: 15px;
  background:  #03015D;
  color:white;
  cursor: pointer;
}
#login .container .content .error {
  background: #F29494;
  padding: 1.4vh 2vw;
  border-radius: 7px;
  width:22vw;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
}
#login .container .content .forgot {
  color: rgb(139, 139, 139);
  font-family: "Poppins", sans-serif;
  font-weight: 300; 
  font-size:13px;
  margin-top: 2vh;
}
#login .container .content .forgot a{
  color: #03015D;
  font-family: "Poppins", sans-serif;
  font-weight: 700; 
  font-size:13px;
  margin-top: 2vh;
  text-decoration: none;
}



@media(max-width: 600px) {
  #login .container {
    width: 100% !important;
    height: 100% !important;
    flex-direction: column;
    border-radius: 0;
  }
  #login .container .content {
    width: 100%;
    height:100%;
 }
 #login .container .content:nth-child(2) {
  border-radius: 0;
 }
 #login .container .content:nth-child(1) .into {
  padding: 80px 40px;
}
 #login .container .content:nth-child(1) {
  border-radius: 0;
 }
 #login .container .content:nth-child(2) .into form div:nth-child(1){
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   width: 90%;
 }
 #login .container .content:nth-child(2) .into form {
    padding:20px 30px;
  }
 #login .container .content:nth-child(2) .into form input {
   width: 90%;
 }
 #login .container .content:nth-child(2) .into form .login_section {
  width: 90%;
}
 #login .container .content:nth-child(2) .into form button {
  width: 75% !important;
}
#login .container .content .error {
  padding: 1.5vh 2vw;
  width:90%;
}
}

@media(max-width: 800px) {
  #login .container {
    width: 85vw;
    height: 55vh;
  }
  #login .container .content:nth-child(2) .into form input {
    width: 80% !important;
  }
  #login .container .content:nth-child(2) .into form button {
    width: 32vw;
  }
  #login .container .content .forgot {
    font-size:10px !important;
  }
  #login .container .content .forgot a{
    font-size:10px !important;
  }
}
@media(max-width: 1000px) {
  #login .container {
    width: 75vw;
    height: 55vh;
  }
  #login .container .content:nth-child(2) .into form input {
    width: 80% !important;
  }
  #login .container .content:nth-child(2) .into form button {
    width: 32vw;
  }
  #login .container .content .forgot {
    font-size:10px !important;
  }
  #login .container .content .forgot a{
    font-size:10px !important;
  }
} 




#forgot-password {
  width:100%;
  height: 100vh;
  background-color: #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
}
#forgot-password .container {
  width: 52vw;
  height: 50vh;
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
#forgot-password .container .content {
   width: 50%;
   height:100%;
}
#forgot-password .container .content:nth-child(1) .into{
  padding:10vh 3.5vw;
}
#forgot-password .container .content:nth-child(1) h2 {
  font-family: 'Rubik', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 20px;
}
#forgot-password .container .content:nth-child(1) p {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 12px;
  margin-top:2.5px;
}
#forgot-password .container .content:nth-child(1) a {
  font-family: 'Poppins', sans-serif;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
}
#forgot-password .container .content:nth-child(2) {
  border-radius: 15px;
  background-color: #ffffff;
}
#forgot-password .container .content:nth-child(1) {
  border-radius: 15px;
  background-color: #03015D;
}
#forgot-password .container .content:nth-child(2) .into  {
  position: relative;
  height:100%;
}
#forgot-password .container .content:nth-child(2) .into form {
  padding: 4vh 1.8vw;
}
#forgot-password .container .content:nth-child(2) .into .forgot-password_section{
  position: absolute;
  bottom: 4vh;
}

#forgot-password .container .content:nth-child(2) .into form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
  width: 22vw;
   padding: 1.8vh 15px;
   border: 1px solid #C4C4C4;
   border-radius: 7px;
   margin-top: 15px;
   outline: none;
   font-size: 15px;
}
#forgot-password .container .content:nth-child(2) .into form button {
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  width: 22vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 15px;
  outline: none;
  font-size: 15px;
  background:  #03015D;
  color:white;
  cursor: pointer;
}
#forgot-password .container .content .error {
  background: #9cee6c;
  padding: 1.4vh 2vw;
  border-radius: 7px;
  width:22vw;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
}
#forgot-password .container .content .forgot {
  color: rgb(139, 139, 139);
  font-family: "Poppins", sans-serif;
  font-weight: 300; 
  font-size:13px;
  margin-top: 2vh;
}
#forgot-password .container .content .forgot a{
  color: #03015D;
  font-family: "Poppins", sans-serif;
  font-weight: 700; 
  font-size:13px;
  margin-top: 2vh;
  text-decoration: none;
}

@media(max-width: 600px) {
  #forgot-password .container {
    width: 100% !important;
    height: 100% !important;
    flex-direction: column;
    border-radius: 0;
  }
  #forgot-password .container .content {
    width: 100%;
    height:100%;
 }
 #forgot-password .container .content:nth-child(2) {
  border-radius: 0;
 }
 #forgot-password .container .content:nth-child(1) .into {
  padding: 80px 40px;
}
 #forgot-password .container .content:nth-child(1) {
  border-radius: 0;
 }
 #forgot-password .container .content:nth-child(2) .into form div:nth-child(1){
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   width: 90%;
 }
 #forgot-password .container .content:nth-child(2) .into form {
    padding:20px 30px;
  }
 #forgot-password .container .content:nth-child(2) .into form input {
   width: 90%;
 }
 #forgot-password .container .content:nth-child(2) .into form .forgot-password_section {
  width: 90%;
}
 #forgot-password .container .content:nth-child(2) .into form button {
  width: 75% !important;
}
#forgot-password .container .content .error {
  padding: 1.5vh 2vw;
  width:90%;
}
}

@media(max-width: 800px) {
  #forgot-password .container {
    width: 85vw;
    height: 55vh;
  }
  #forgot-password .container .content:nth-child(2) .into form input {
    width: 80% !important;
  }
  #forgot-password .container .content:nth-child(2) .into form button {
    width: 32vw;
  }
  #forgot-password .container .content .forgot {
    font-size:10px !important;
  }
  #forgot-password .container .content .forgot a{
    font-size:10px !important;
  }
}
@media(max-width: 1000px) {
  #forgot-password .container {
    width: 75vw;
    height: 55vh;
  }
  #forgot-password .container .content:nth-child(2) .into form input {
    width: 80% !important;
  }
  #forgot-password .container .content:nth-child(2) .into form button {
    width: 32vw;
  }
  #forgot-password .container .content .forgot {
    font-size:10px !important;
  }
  #forgot-password .container .content .forgot a{
    font-size:10px !important;
  }
}

#update-profile {
  overflow: hidden;
}
#update-profile .container {
  padding:20px 50px;
}
#update-profile h2 {
  font-family: 'Rubik', sans-serif;
  font-size:40px;
  color: #03015D;
  padding-bottom: 20px;
}
#update-profile .container form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
  width: 25vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 10px;
  outline: none;
  font-size: 15px;
}
#update-profile .container button {
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  width: 25vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 10px;
  outline: none;
  font-size: 15px;
  background:  #03015D;
  color:white;
  cursor: pointer;
}
#update-profile .container form {
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin-top:25px;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  border-radius:5px;
  color: white;
  background-color: #03015D;
  display: inline-block;
  margin-top:15px;
  padding:5px 10px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #03023a;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

@media(max-width: 1100px) {
  #update-profile .container form input {
    width: 300px;
  }
  #update-profile .container button {
    width: 300px;
  }
}
.bg-prf {
  width: 75px !important;
  height: 75px !important;
}
@media(max-width: 500px) {
  #update-profile .container form input {
    width: 100%;
  }
  #update-profile .container button {
    width: 100%;
  }
}
@media(min-width: 1600px) {
  #update-profile .container {
    padding:50px 50px;
  }
  #update-profile h2 {
    font-size:50px;
    padding-bottom: 25px;
  }
  .bg-prf {
    width: 85px !important;
    height: 85px !important;
  }
  .inputfile + label {
    margin-top:25px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
